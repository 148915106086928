export class OptixSettingName {
    public static readonly Currency: string = 'Currency';
    public static readonly CurrencySymbol: string = 'CurrencySymbol';
    public static readonly DefaultHomePage: string = 'DefaultHomePage';
    public static readonly XpasHomeUrl: string = 'XpasHomeUrl';
    public static readonly XpasLiteHomeUrl: string = 'XpasLiteHomeUrl';
    public static readonly ETS: string = 'ETS';
    public static readonly DisplayEnergyFilter: string = 'DisplayEnergyFilter';
    public static readonly BaseInterval: string = 'BaseInterval';
    public static readonly RunningHoursUnitTypes: string = 'RunningHoursUnitTypes';
    public static readonly PemsUnitTypes: string = 'PemsUnitTypes';
    public static readonly EnergyFilterOptions: string = 'EnergyFilterOptions';
    public static readonly AnalysisPageShowAbsorbedPowerForEquipment: string = 'AnalysisPageShowAbsorbedPowerForEquipment';
    public static readonly UserManualUrl: string = 'UserManualUrl';
    public static readonly EmissionsCo2Label: string = 'CO<sub>2</sub>';
    public static readonly OperatingStatusLevels: string = 'OperatingStatusLevels';
    public static readonly LaePeriodTableTopX: string = 'LaePeriodTableTopX';
}